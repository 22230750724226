.blue-text {
  color: #0f6eff;
}

.purple-text {
  color: #3300cc;
}

.grey-text {
  color: #9b9999;
}

.medium {
  font-weight: 500 !important;
}

.semi-bold {
  font-weight: 600 !important;
}

.bold {
  font-weight: 800 !important;
}

.page-wrap {
  padding-bottom: 727px;
}

@media (max-width: 600px) {
  .page-wrap {
    padding-bottom: 1675px !important;
  }
  .header-logo {
    height: 16px !important;
  }
}

.clickable {
  text-decoration: none;
}

.clickable:hover {
  opacity: 0.7;
  cursor: pointer;
}

.header-logo {
  height: 24px;
}

.Mui-active {
  color: #0f6eff !important;
}
.Mui-completed {
  color: #3300cc !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

table {
  border-collapse: collapse;
}
