.social-logo {
  height: 30px;
  margin-right: 16px;
}

.footer-logo {
  height: 140px;
}

.footer-text {
  text-align: end;
}

.footer-link {
  text-decoration: none;
  color: black;
}

.footer-mobile {
  margin: 8px 0px;
}
